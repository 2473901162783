@import '../../resources/colors-and-fonts';

.selected-locker-wrapper {
  display: flex;
  height: 100vh;

  .selected-locker-data-wrapper {
    width: 60%;
    display: block;
  }

  .map-wrapper {
    width: 40%;
  }

  .mapboxgl-marker:hover{
    cursor: auto;
  }

  .mapboxgl-canvas-container {
    cursor: auto;
  }

  .mapboxgl-canvas-container:active {
    cursor: auto;
  }
}