@import '../../resources/colors-and-fonts';

.header {
  display: flex;
  align-items: center;
  justify-content: left;
  min-height: 50px;
  color: $lighter_text;
  font-family: $font_family;
  font-weight: $font_weight_bold;
  font-size: 20px;
  padding-left: 10px;

  @media (min-width: 600px){
    font-size: 23px;
  }


  .left {
    width: auto;

    .company-name {
      display: none;

      @media (min-width: 600px){
        display: inline;
      }
    }
  }

  .right {
    display: none;

    @media (min-width: 600px){
      display: flex;
      margin-left: auto;
      margin-right: 5px;
    }


    .dexpress-logo {
      height: 40px;
    }
  }

  .success-title {
    display: flex;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 25px;
    font-weight: $font-weight_bold;

    .title-text {
      padding-top: 2px;
    }
  }
}
