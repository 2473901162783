@import '../resources/colors-and-fonts';

.mily-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background-color: $selection_color;
  font-size: 16px;
  font-weight: $font_weight_normal;
  color: $lighter_text;
  width: 100px;

  &:hover {
    cursor: pointer;
  }

  &.cancel {
    background-color: $kashmir_blue;
  }
}
