@import '../../resources/colors-and-fonts';

.selected-locker-data {
  position:relative;
  display: flex;
  height: calc(100vh - 85px);
  font-family: $font_family;
  font-weight: $font-weight_normal;
  color: $lighter_text;
  padding-left: 20px;
  align-items: center;

  .data-table {
    width: 100%;
    display: block;
    .name-wrapper {
      font-size: 30px;
      font-weight: $font-weight_bold;
      padding-bottom: 20px;
    }

    .popup-content {
      display: flex;
      .icon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-right: 10px;
        padding-bottom: 5px;

        .icon {
          font-size: 25px;
          color: $kashmir_blue
        }

        .icon-alarm {
          font-size: 30px;
        }
      }

      .data-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 40px;
        padding-bottom: 5px;

        .data {
          font-size: 20px;
        }
      }
    }
  }

  .change-button {
    display: flex;
    width:100%;
    padding-top: 20px;
    text-decoration: underline;
    font-size: 20px;
  }

  .change-button :hover {
    cursor: pointer;
    color: $selection_color;
    text-decoration: underline;
    text-decoration-color: $selection_color;
  }
}
