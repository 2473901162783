@import '../../resources/colors-and-fonts';

.select-dropdown-wrapper {
  position: absolute;
  z-index: 15;
  width: 220px;
  left: 10px;
  top: 60px;

  @media (min-width: 350px){
    width: 300px;
  }

  @media (min-width: 600px){
    width: 340px;
  }
}
