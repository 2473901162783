@import '../../resources/colors-and-fonts';

.footer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  height: 30px;
  color:$lighter_text;
  background-color: $background_color;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-bottom: 5px;

  .footer-content {
    position: relative;
    font-family: $font_family;
    font-weight: $font_weight_normal;
    font-size: 14px;

    .mily-logo {
      position: relative;
      height: 20px;
      top: 4px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
