@import '../../resources/colors-and-fonts';

.locker-select {
  width: 100%;
  display: flex;
  align-items: center;

  .select {
    width: 100%;
    min-height: 20px !important; // sass-lint:disable-line no-important
    font-size: 14px;

    .select-item {
      display: block;

      .locker-name {
        font-weight: bold;
        font-size: 15px;
      }

      .locker-data-wrapper {
        display: grid;
        align-items: center;
        grid-template-columns: 40px 1fr;
        grid-template-rows: 35px 35px;
        grid-template-areas:
        'icon1 address'
        'icon2 town'
      }

      .icon-flag {
        grid-area: icon1;
      }

      .icon-town {
        grid-area: icon2;
      }

      .data {
        font-size: 14px;
        font-family: $font_family;
        font-weight: $font_weight_normal;
        padding-left: 10px;
      }

      .icon {
        align-self: center;
        justify-self: center;
        font-size: 20px;
        color: rgb(160, 167, 180);
      }
    }
  }
}
