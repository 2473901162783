@import '../resources/colors-and-fonts';

.map-box-wrapper {
  position: relative;
  height: calc(100vh - 85px);

  .map-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }

  .mapboxgl-ctrl {
    a {
      &:last-child {
        display: none;
      }
    }
  }

  .mapboxgl-ctrl-logo {
    display: none !important; // sass-lint:disable-line no-important
  }

  .mapboxgl-ctrl-scale {
    background: transparent;
  }

  .mapboxgl-popup-content {
    font-family: $font_family;
    font-size: 12px;
    padding: 5px 5px 5px 5px;

    .mapboxgl-popup-close-button {
      display: none;
    }

  }

  .mapboxgl-popup-anchor-top {
    .mapboxgl-popup-tip {
      border-bottom-color: $tile_background_color;
    }
  }

  .mapboxgl-popup-anchor-top-left {
    .mapboxgl-popup-tip {
      border-bottom-color: $tile_background_color;
    }
  }

  .mapboxgl-popup-anchor-top-right {
    .mapboxgl-popup-tip {
      border-bottom-color: $tile_background_color;
    }
  }

  .mapboxgl-popup-anchor-bottom {
    .mapboxgl-popup-tip {
      border-top-color: $tile_background_color;
    }
  }

  .mapboxgl-popup-anchor-bottom-left {
    .mapboxgl-popup-tip {
      border-top-color: $tile_background_color;
    }
  }

  .mapboxgl-popup-anchor-bottom-right {
    .mapboxgl-popup-tip {
      border-top-color: $tile_background_color;
    }
  }

  .mapboxgl-popup-anchor-left {
    .mapboxgl-popup-tip {
      border-right-color: $tile_background_color;
    }
  }

  .mapboxgl-popup-anchor-right {
    .mapboxgl-popup-tip {
      border-left-color: $tile_background_color;
    }
  }

  .region-displayer {
    display: flex;
    padding: 0 0 0 5px;
    position: absolute;
    bottom: -12px;
    right: 165px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-stretch: normal;
    font-size: 12px;
    line-height: 20px;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #000000bf;
    background-color: #ffffff80;
    z-index: 10;
  }
}

.selected {
  height: 100%;
}
