@import '../../resources/colors-and-fonts';

.map-marker-popup {
  display: block;
  margin:0;
  padding: 10px;
  font-family: $font_family;
  font-weight: $font-weight_normal;
  color: $lighter_text;
  justify-content: center;

  .name-wrapper {
    font-size: 17px;
    font-weight: $font-weight_bold;
    margin-bottom: 15px;

    @media (min-width: 600px){
      font-size: 20px;
    }
  }

  .popup-content {
    display: flex;
    padding-bottom: 10px;

    .icon-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 35px;
      margin-right: 10px;

      .icon {
        font-size: 17px;
        color: $kashmir_blue;

        @media (min-width: 600px){
          font-size: 20px;
        }
      }

      .icon-alarm {
        font-size: 22px;

        @media (min-width: 600px){
          font-size: 25px;
        }
      }
    }

    .data-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 40px;

      .data {
        font-size: 13px;

        @media (min-width: 600px){
          font-size: 16px;
        }
      }
    }
  }

  .button-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
