@import '../../resources/colors-and-fonts';

.circle-wrapper {
  padding-right: 10px;
  padding-top: 5px;

  .dot {
    height: 30px;
    width: 30px;
    background-color: $success_color;
    border-radius: 50%;

    .icon {
      position: absolute;
      padding-left: 5px;
      padding-top: 5px;
      font-size: 20px;
    }
  }
}

