$icons-font-path: "fonts/icons" !default;
$icons-font-suffix: "wyjpe5" !default;

@font-face {
  font-family: "icomoon";
  src: url("#{$icons-font-path}/icomoon.eot?#{$icons-font-suffix}");
  src: url("#{$icons-font-path}/icomoon.eot?#{$icons-font-suffix}#iefix") format("embedded-opentype"),
    url("#{$icons-font-path}/icomoon.ttf?#{$icons-font-suffix}") format("truetype"),
    url("#{$icons-font-path}/icomoon.woff?#{$icons-font-suffix}") format("woff"),
    url("#{$icons-font-path}/icomoon.svg?#{$icons-font-suffix}#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-history:before {
  content: "\e943";
}
.icon-play:before {
  content: "\e941";
}
.icon-stop:before {
  content: "\e942";
}
.icon-road:before {
  content: "\e93f";
}
.icon-gas-station:before {
  content: "\e93e";
}
.icon-rotate-left:before {
  content: "\e93d";
}
.icon-map-search:before {
  content: "\e93c";
}
.icon-magic:before {
  content: "\e93a";
}
.icon-block:before {
  content: "\e93b";
}
.icon-hexagon-multiple:before {
  content: "\e938";
}
.icon-hexagon:before {
  content: "\e939";
}
.icon-delete:before {
  content: "\e937";
}
.icon-account-group:before {
  content: "\e936";
}
.icon-mail:before {
  content: "\e92e";
}
.icon-person:before {
  content: "\e934";
}
.icon-phone:before {
  content: "\e935";
}
.icon-eraser:before {
  content: "\e92d";
}
.icon-mily-logo:before {
  content: "\e92f";
}
.icon-edit:before {
  content: "\e930";
}
.icon-list-alt:before {
  content: "\e931";
}
.icon-upload-file:before {
  content: "\e932";
}
.icon-warning:before {
  content: "\e933";
}
.icon-lightbulb:before {
  content: "\e92c";
}
.icon-open-in-new:before {
  content: "\e92a";
}
.icon-keyboard-arrow-up:before {
  content: "\e92b";
}
.icon-package:before {
  content: "\e928";
}
.icon-circle:before {
  content: "\e920";
}
.icon-draw-polygon:before {
  content: "\e921";
}
.icon-rectangle:before {
  content: "\e922";
}
.icon-select-object:before {
  content: "\e923";
}
.icon-add:before {
  content: "\e924";
}
.icon-remove:before {
  content: "\e925";
}
.icon-save:before {
  content: "\e926";
}
.icon-touch-app:before {
  content: "\e927";
}
.icon-cloud-download:before {
  content: "\e91a";
}
.icon-cloud-upload:before {
  content: "\e91b";
}
.icon-triangle-vector:before {
  content: "\e91c";
}
.icon-vector-circle:before {
  content: "\e91d";
}
.icon-vector-rectangle:before {
  content: "\e91e";
}
.icon-build:before {
  content: "\e919";
}
.icon-archive-outlined:before {
  content: "\e914";
}
.icon-assignment-turned-in-outlined:before {
  content: "\e915";
}
.icon-location-on-outlined:before {
  content: "\e916";
}
.icon-markunread-mailbox-outlined:before {
  content: "\e917";
}
.icon-outlined-flag-outlined:before {
  content: "\e91f";
}
.icon-wrong-location-outlined:before {
  content: "\e918";
}
.icon-delivery-van:before {
  content: "\e913";
}
.icon-outlined-flag:before {
  content: "\e900";
}
.icon-alarm:before {
  content: "\e901";
}
.icon-archive:before {
  content: "\e902";
}
.icon-assignment-turned-in:before {
  content: "\e903";
}
.icon-keyboard-arrow-down:before {
  content: "\e904";
}
.icon-location-on:before {
  content: "\e905";
}
.icon-markunread-mailbox:before {
  content: "\e906";
}
.icon-map:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-event:before {
  content: "\e909";
}
.icon-exit-to-app:before {
  content: "\e90a";
}
.icon-info:before {
  content: "\e90b";
}
.icon-keyboard-arrow-left:before {
  content: "\e90c";
}
.icon-keyboard-arrow-right:before {
  content: "\e90d";
}
.icon-language:before {
  content: "\e90e";
}
.icon-local-shipping:before {
  content: "\e90f";
}
.icon-timeline:before {
  content: "\e910";
}
.icon-wrong-location:before {
  content: "\e911";
}
.icon-bar-chart:before {
  content: "\e912";
}
.icon-stack:before {
  content: "\e929";
}
.icon-storefront:before {
  content: "\e940";
}
.icon-office:before {
  content: "\e903";
}
.icon-flag:before {
  content: "\e9cc";
}
.icon-checkmark2:before {
  content: "\ea11";
}
.icon-checkmark:before {
  content: "\ea10";
}
