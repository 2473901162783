@import './resources/colors-and-fonts';

.page-wrapper {
  height: 100vh;
  width: 100%;
  background-color:$background_color;

  .mapboxgl-popup-content {
    background-color:$background_color;
  }

  .mapboxgl-marker:hover{
    cursor: pointer;
  }
}